<template>
  <div>
    <validation-observer ref="simpleRules">

      <b-row>
        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-2 d-flex justify-content-between align-items-center">
              <h3 class="mb-0">
                {{ $t('key-68') }}
              </h3>

              <button
                class="btn btn-gradient-primary"
                @click="addRold()"
              >
                {{ $t('saveData') }}
              </button>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="3" />
                <b-col md="6">
                  <b-form-group
                    label-for="h-role-name"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('key-69') }} <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Role"
                      rules="required"
                    >
                      <b-form-input
                        id="h-role-name"
                        v-model="role"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3" />
              </b-row>
            </div>
          </div>
        </b-col>

        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('details') }}
              </h3>
            </div>

            <div class="p-2">
              <div class="d-flex align-items-center">
                <div class="w-45">
                  <div class="card border">
                    <div class="header-cardx p-1 d-flex justify-content-between align-items-center">
                      <div class="d-flex align-items-center">
                        <b-form-checkbox
                          v-model="checkAll2"
                          value="true"
                          class="custom-control-primary"
                          :indeterminate="isIndeterminate2"
                          @change="checkAllOptions"
                        />
                        <h6 class="mb-0">
                          <span v-if="roleDetail2.length">{{ roleDetail2.length }}/</span>{{ optionsRight.length }} items
                        </h6>
                      </div>

                      <h6 class="mb-0">
                        <!-- {{ $t('listlot') }} --> รายการสิทธิ์ใช้งาน
                      </h6>
                    </div>

                    <div class="p-1">
                      <div class="income-order-search order-search">
                        <div class="anan-input search-input">
                          <div class="anan-input__inner anan-input__inner--normal">
                            <input
                              v-model="searchRight"
                              type="text"
                              placeholder="Search here..."
                              class="anan-input__input"
                            >
                            <div class="anan-input__suffix">
                              <i class="anan-input__clear-btn anan-icon" />
                              <i class="anan-input__suffix-icon anan-icon">
                                <i class="fal fa-search" />
                              </i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="role-items">
                        <b-form-group class="mt-1">
                          <b-form-checkbox
                            v-for="option in filteredOptions2"
                            :key="option.level"
                            v-model="roleDetail2"
                            :value="option.level"
                            name="flavour-3a"
                          >
                            <div class="mb-1">
                              {{ option.name }}
                            </div>
                          </b-form-checkbox>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="w-10 p-50">
                  <div>
                    <button
                      type="button"
                      class="btn btn-primary btn-sm btn-icon-only w-100 mb-1"
                      @click="moveToRight"
                    >
                      <i class="fas fa-arrow-to-left" />
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      class="btn btn-primary btn-sm btn-icon-only w-100"
                      @click="moveToLeft"
                    >
                      <i class="fas fa-arrow-from-left" />
                    </button>
                  </div>
                </div>

                <div class="w-45">
                  <div class="card border">
                    <div class="header-cardx p-1 d-flex justify-content-between align-items-center">
                      <div class="d-flex align-items-center">
                        <b-form-checkbox
                          v-model="checkAll"
                          value="true"
                          class="custom-control-primary"
                          :indeterminate="isIndeterminate"
                          @change="checkAllOptions"
                        />
                        <h6 class="mb-0">
                          <span v-if="roleDetail.length">{{ roleDetail.length }}/</span>{{ optionsLeft.length }} items
                        </h6>
                      </div>

                      <h6 class="mb-0">
                        <!-- {{ $t('selectlot') }} --> สิทธิ์ใช้งานที่เลือก
                      </h6>
                    </div>

                    <div class="p-1">
                      <div class="income-order-search order-search">
                        <div class="anan-input search-input">
                          <div class="anan-input__inner anan-input__inner--normal">
                            <input
                              v-model="searchLeft"
                              type="text"
                              placeholder="Search here..."
                              class="anan-input__input"
                            >
                            <div class="anan-input__suffix">
                              <i class="anan-input__clear-btn anan-icon" />
                              <i class="anan-input__suffix-icon anan-icon">
                                <i class="fal fa-search" />
                              </i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="role-items">
                        <b-form-group class="mt-1">
                          <b-form-checkbox
                            v-for="option in filteredOptions"
                            :key="option.level"
                            v-model="roleDetail"
                            :value="option.level"
                            name="flavour-3a"
                          >
                            <div class="mb-1">
                              {{ option.name }}
                            </div>
                          </b-form-checkbox>
                        </b-form-group>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      role: '',
      searchLeft: '',
      searchRight: '',
      checkAll: false,
      checkAll2: false,
      roleDetail: [],
      roleDetail2: [],
      optionsRight: [],
      optionsLeft: [],
      required,
      email,
      name: '',
      category: '',
      code: '',
      details: '',
      selectedFiles: [],
      uploadedImages: [],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    isIndeterminate() {
      return this.roleDetail.length > 0 && this.roleDetail.length < this.optionsLeft.length
    },
    filteredOptions() {
      if (this.searchLeft) {
        const searchTerm = this.searchLeft.toLowerCase()
        return this.optionsLeft.filter(option => option.name.toLowerCase().includes(searchTerm))
      }
      return this.optionsLeft
    },

    isIndeterminate2() {
      return this.roleDetail2.length > 0 && this.roleDetail2.length < this.optionsRight.length
    },
    filteredOptions2() {
      if (this.searchRight) {
        const searchTerm2 = this.searchRight.toLowerCase()
        return this.optionsRight.filter(option => option.name.toLowerCase().includes(searchTerm2))
      }
      return this.optionsRight
    },
  },
  mounted() {
    this.GetSubrole()
  },
  methods: {
    checkAllOptions() {
      if (this.checkAll) {
        this.roleDetail = this.optionsLeft.map(option => option.level)
      } else {
        this.roleDetail = []
      }
      if (this.checkAll2) {
        this.roleDetail2 = this.optionsRight.map(option => option.level)
      } else {
        this.roleDetail2 = []
      }
    },
    moveToLeft() {
      this.optionsLeft = this.optionsLeft.concat(this.optionsRight.filter(option => this.roleDetail2.includes(option.level)))
      this.optionsRight = this.optionsRight.filter(option => !this.roleDetail2.includes(option.level))
      this.roleDetail2 = []
      this.checkAll = false
      this.checkAll2 = false
    },
    moveToRight() {
      this.optionsRight = this.optionsRight.concat(this.optionsLeft.filter(option => this.roleDetail.includes(option.level)))
      this.optionsLeft = this.optionsLeft.filter(option => !this.roleDetail.includes(option.level))
      this.roleDetail = []
      this.checkAll = false
      this.checkAll2 = false
    },
    removeItem(level) {
      this.optionsRight = this.optionsRight.filter(option => option.level !== level)
    },
    addAddr() {
      this.$refs.addRules.validate().then(success => {
        if (success) {
          this.$bvModal.hide('add-addr')
        }
      })
    },
    showAddAddr() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.$bvModal.show('modal-1')
    },
    async GetSubrole() {
      try {
        const { data: res } = await this.$http.get('/moduledetail/index')
        this.optionsRight = res
      } catch (error) {
        console.log(error)
      }
    },
    addRold() {
      this.$refs.simpleRules.validate().then(success => {
        if (success && this.filteredOptions.length > 0) {
          console.log(this.filteredOptions)
          const subrole = this.filteredOptions.map(option => option.level).join(',')
          const obj = {
            name: this.role,
            sub_role: subrole,
          }
          this.$http
            .post('/modulelist/storeAndupdate', obj)
            .then(() => {
              this.role = ''
              this.searchLeft = ''
              this.searchRight = ''
              this.checkAll = false
              this.checkAll2 = false
              this.roleDetail = []
              this.roleDetail2 = []
              this.Alert(true, 'เพิ่มข้อมูลสำเร็จ', '')
              this.$router.push({ name: 'admin-role' })
            })
            .catch(error => {
              this.Alert(false, 'เพิ่มข้อมูลไม่สำเร็จ', '')
              console.log(error)
              // this.SwalError(error.response.data.message)
            })
        } else {
          this.Alert(false, 'กรุณากรอกข้อมูลให้ครบถ้วน', '')
        }
      })
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? 'success' : 'error'}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
